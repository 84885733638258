@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

html.has-scroll-smooth {
  overflow: hidden;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.aboutbackground {
}

.aboutbackground::before {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 30px;
  left: -60px;
  background-color: #000;
  width: 110%;
  height: 100%;
}

.servicesright {
}

.servicesright::before {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 0px;
  left: -50px;
  background-color: #000;
  width: 105%;
  height: 105%;
}

.servicesleft {
}

.servicesleft::before {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #000;
  width: 105%;
  height: 105%;
}

html.lenis {
  height: auto;
}
.lenis.lenis-smooth {
  scroll-behavior: auto;
}
.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}
.lenis.lenis-stopped {
  overflow: hidden;
}
.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

.perspective {
}

.project-and {
  flex: 1 0 calc(25% - 6px); /* 25% width for each column, subtracting the gap */
  max-width: calc(25% - 6px);
  margin-bottom: 6px;
}

.project-category {
  width: 100%; /* Make the content fill the entire width of the wrapper */
  height: 100%; /* Make the content fill the entire height of the wrapper */
}

.projects-category {
  flex: 1 0 calc(20% - 16px); /* 20% width for each column, subtracting the gap */
  max-width: calc(20% - 16px); /* Set max-width to handle smaller screens */
  margin-bottom: 16px;
}

.project-three {
  flex: 1 0 calc(33.333% - 6px); /* 33.333% width for each column, subtracting the gap */
  max-width: calc(33.333% - 6px); /* Set max-width to handle smaller screens */
  margin-bottom: 6px; /* Add margin to the bottom to create the gap between rows */
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Add this to your existing styles or create a new CSS file */
.image-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.modal-content {
  position: relative;
  max-width: 99%;
  max-height: 99%;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.modal-content img {
  width: 100%;
  height: 100%; /* Set the height to 100% */
  object-fit: contain; /* Preserve the aspect ratio and fit the image within the container */
}

/* Customize the styles as needed */

.custom-container-as {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 30px;
  width: 100%;
}

.custom-grid {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 10% 90%;
}

.mySwiper {
  width: 100%;
  max-width: 100%;
  max-height: 100vh;
  min-height: 0;
  min-width: 0;
}

.custom-prev {
  position: absolute;
  bottom: 50%;
  left: 0%;
  transform: translate(0%, 50%);
}

.custom-next {
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(-0%, -50%);
}
